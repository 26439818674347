<style src="./Spinner.css" scoped></style>

<template>
  <div class="loading-spinner">
    <img
      :src="require('presentation/assets/img/loading.svg')"
      data-test="spinner"
    />
  </div>
</template>
