import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    });
    return {
      t,
    };
  },
  components: {
    VueperSlides,
    VueperSlide,
  },
  data: () => ({
    autoPlaying: true,
    internalAutoPlaying: true,
    slides: [
      {
        title: 'Slide 1',
        content: {
          bgImage: 'banner1.png',
          h3Message: '',
          h1Message: '',
          bttnText: 'Shop Now',
        },
      },
      {
        title: 'Slide 2',
        content: {
          bgImage: 'banner2.png',
          h3Message: '',
          h1Message: '',
          bttnText: 'Shop Now',
        },
      },
      {
        title: 'Slide 3',
        content: {
          bgImage: 'banner3.png',
          h3Message: '',
          h1Message: '',
          bttnText: 'Shop Now',
        },
      },
    ],
  }),
};
